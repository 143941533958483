.app-footer {
  background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
  padding: 0.75rem 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  margin-top: auto;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.footer-text {
  font-size: 0.9rem;
  color: #666;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.heart-icon {
  color: #ff6b6b;
  font-size: 0.9rem;
  animation: heartbeat 1.5s ease infinite;
}

.code-icon {
  color: #2193b0;
  font-size: 0.9rem;
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: #666;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.social-link:hover {
  transform: translateY(-2px);
  color: #2193b0;
}

.footer-copyright {
  color: #666;
  font-size: 0.8rem;
}

@keyframes heartbeat {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@media (max-width: 768px) {
  .app-footer {
    padding: 0.5rem 0;
  }
  
  .footer-content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .footer-text {
    font-size: 0.8rem;
    text-align: center;
  }
  
  .social-link {
    font-size: 1rem;
  }
}
