.auth-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #42acc6;
  position: relative;
  overflow: hidden;
}

.auth-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.shape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 50%;
}

.shape:nth-child(1) {
  width: 500px;
  height: 500px;
  top: -250px;
  right: -100px;
  animation: float 6s ease-in-out infinite;
}

.shape:nth-child(2) {
  width: 400px;
  height: 400px;
  bottom: -200px;
  left: -100px;
  animation: float 8s ease-in-out infinite reverse;
}

.auth-card {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 2;
  animation: fadeIn 0.5s ease;
}

.auth-header {
  text-align: center;
  margin-bottom: 30px;
}

.auth-header h1 {
  color: #42acc6;
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.auth-header p {
  color: #666;
  font-size: 1rem;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-group {
  position: relative;
  background: #f8f9fe;
  border-radius: 10px;
}

.input-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #42acc6;
  font-size: 1.2rem;
}

.input-group input {
  width: 100%;
  padding: 15px 15px 15px 45px;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  background: transparent;
}

.input-group input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #42acc6;
}

.auth-button, .home-button {
  color: white;
  padding: 15px;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background: #42acc6;
  margin-top: 10px;
}

.auth-button:hover, .home-button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.button-icon {
  font-size: 1.2rem;
}

.error-message {
  color: #e74c3c;
  text-align: center;
  font-size: 0.9rem;
  padding: 8px;
  background: #fdeaea;
  border-radius: 8px;
  animation: shake 0.5s ease;
}

.demo-info {
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

@media (max-width: 480px) {
  .auth-card {
    margin: 20px;
    padding: 30px 20px;
  }

  .auth-header h1 {
    font-size: 2rem;
  }

  .input-group input {
    padding: 12px 12px 12px 40px;
  }

  .auth-button, .home-button {
    padding: 12px;
  }
}
