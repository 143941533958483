/* Modern App Styles */
.modern-app {
  min-height: 100vh;
  color: #333;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.particles-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Hero Section */
.hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%);
  backdrop-filter: blur(10px);
}

.hero-content {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.profile-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.profile-picture:hover {
  transform: scale(1.05);
}

.hero-text {
  text-align: left;
}

.hero-text h1 {
  font-size: 3rem;
  margin: 0;
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.hero-text h2 {
  font-size: 1.5rem;
  margin: 0.5rem 0 1.5rem;
  color: #666;
  font-weight: 500;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: #666;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-link:hover {
  color: #2193b0;
  transform: translateY(-2px);
}

/* Navigation */
.modern-nav {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 1rem;
  z-index: 100;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modern-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.modern-nav a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.modern-nav a:hover {
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  color: white;
}

/* Sections */
.section {
  padding: 5rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  text-align: center;
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Skills Grid */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.skill-card {
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
  transition: transform 0.3s ease;
}

.skill-card:hover {
  transform: translateY(-5px);
}

.skill-card svg {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #2193b0;
}

.skill-card h3 {
  margin: 0.5rem 0;
  color: #333;
}

.skill-card p {
  color: #666;
  margin: 0;
}

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.project-card {
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-card h3 {
  margin: 0 0 1rem;
  color: #333;
}

.project-card p {
  color: #666;
  margin: 0 0 1.5rem;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.project-tags span {
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.875rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2rem;
  }
  
  .hero-text h2 {
    font-size: 1.25rem;
  }
  
  .modern-nav ul {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .section {
    padding: 3rem 1rem;
  }
  
  .section-title {
    font-size: 2rem;
  }
}

/* Genel Stiller */
.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

.section {
  display: block;

}

.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.section-content {
  text-align: justify;
  margin-bottom: 20px;
}

.project-item {
  list-style: circle;
  margin-bottom: 20px;
  text-align: left;
}

.project-title {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 5px;
}

.project-description {
  display: block;
  text-wrap: balance;
  margin-bottom: 10px;
}

.skills-container {
  display: block;
  flex-wrap: wrap;
  gap: 20px; /* Boşluk miktarını ayarlayın */
}

.skill-item {
  /* width: calc(50% - 10px); Boşluklar için 10px çıkar */
  margin-bottom: 20px;
}

.skill-title {
  margin-bottom: 5px;
}

.skill-bar {
  background-color: #f1f1f1;
  height: 20px; /* Örnek olarak artırabilirsiniz */
  border-radius: 8px;
  overflow: hidden;
}

.skill-progress {
  height: 100%;
  background-color: #007bff; /* Örnek olarak daha belirgin bir renk seçebilirsiniz */
  border-radius: 20px; /* Örnek olarak artırabilirsiniz */
}

.footer-text{
  color: black;
}

.footer {
  text-align: center;
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  background-color: black;
}

@media (max-width: 768px) {
  /* Ekran genişliği 768 piksel veya daha küçükse uygulanacak stil */
  .nav-list {
    flex-direction: column;
  }

  .nav-link {
    display: block;
    margin: 10px; 
    text-decoration: none;
  }
}

/* Navigasyon Bağlantıları */
.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around; /* Bağlantıları yatay olarak eşit aralıklarla dağıtır */
  align-items: center; /* Bağlantıları dikey olarak ortalar */

  margin: 0; /* Kenar boşluğunu sıfırlar */
  padding: 1px 0; /* Bağlantıların iç içe olduğu biraz boşluk ekler */
}

.nav-item {
  margin: 0;
}

.image-container {
  max-width: 100%;
  width: 100%;
  height: auto; /* Genişliği koruyarak yüksekliği otomatik olarak ayarlar */
}

.nav-link {
  text-decoration: none;
  color: black;
  padding: 10px 20px; /* Bağlantıların içindeki dolgu (yarıçaplı kenarlıklar ekler) */
  border-radius: 5px; /* Köşeleri yuvarlar */
  transition: background-color 0.3s; /* Renk değişimini yumuşatır */
}

.nav-link:hover {
  background-color: #ccc; /* Fare üzerine gelindiğinde bağlantı rengini değiştirir */
}

/* Hizmetler Bölümü */
#services {
  background-color: #f9f9f9; /* Arka plan rengi */
  padding: 10; /* Kenar boşluğu */
}

.service {
  background-color: #ffffff; /* Hizmet kutusu arka plan rengi */
  border: 1px solid #ddd; /* Hizmet kutusu kenar çizgisi */
  border-radius: 10px; /* Köşe yuvarlama */
  padding: 20px; /* Hizmet kutusu iç boşluğu */
  margin-bottom: 20px; /* Hizmet kutuları arası boşluk */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Gölgelendirme */
}

.service-title {
  font-size: 24px; /* Başlık yazı boyutu */
  color: #333; /* Başlık metin rengi */
  margin-bottom: 10px; /* Başlık ile açıklama arası boşluk */
}

.service-description {
  font-size: 16px; /* Açıklama yazı boyutu */
  color: #666; /* Açıklama metin rengi */
}

/* İletişim Bilgileri */
#contact {
  background-color: #333; /* Arka plan rengi */
  color: #fff; /* Metin rengi */
  padding: 40px; /* Kenar boşluğu */
}

.contact-title {
  font-size: 24px; /* Başlık yazı boyutu */
  margin-bottom: 20px; /* Başlık ile iletişim bilgileri arası boşluk */
}

.contact-info {
  font-size: 16px; /* İletişim bilgisi yazı boyutu */
  margin-bottom: 10px; /* İletişim bilgisi arası boşluk */
}

/* İletişim Formu */
.contact-form {
  max-width: 400px; /* Formun maksimum genişliği */
  margin: 0 auto; /* Ortala */
}

.form-input {
  width: 100%; /* Giriş alanının genişliği */
  padding: 10px; /* İç boşluk */
  margin-bottom: 20px; /* Giriş alanları arası boşluk */
}

.form-submit {
  background-color: #61dafb; /* Gönder düğmesi arka plan rengi */
  color: #fff; /* Gönder düğmesi metin rengi */
  padding: 10px 20px; /* Gönder düğmesi iç boşluk */
  border: none; /* Kenar çizgisi yok */
  border-radius: 5px; /* Köşe yuvarlama */
  cursor: pointer; /* Kursor tipi */
}

.form-submit:hover {
  background-color: #333; /* Gönder düğmesine hover olduğunda arka plan rengi */
}

/* İş Deneyimleri */
.experience-item {
  margin-bottom: 30px;
}

.experience-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.experience-date {
  font-size: 16px;
  color: #777;
  margin-bottom: 10px;
}

.experience-description {
  font-size: 16px;
  line-height: 1.4;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

body {
  margin: 0;
  overflow: auto; /* auto olarak değiştirin */
}

.bgCanvas{
 position: absolute !important;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
