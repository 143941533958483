.app-header {
  background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.welcome-text {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
  font-weight: 600;
  background: linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%);
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-icon {
  font-size: 1.2rem;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #ff6b6b 0%, #ff8e8e 100%);
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logout-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logout-icon {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .app-header {
    padding: 1rem;
  }
  
  .welcome-text {
    font-size: 1.2rem;
  }
  
  .logout-button {
    padding: 0.4rem 0.8rem;
  }
  
  .home-button {
    width: 35px;
    height: 35px;
  }
}
