/* Private Layout Shared Styles */
.private-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.private-layout-main {
  flex: 1;
  padding: 2rem;
}

/* Dashboard Styles */
.dashboard {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  margin-bottom: 2rem;
}

.dashboard-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.dashboard-card {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.dashboard-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.dashboard-card-content {
  color: #666;
}

.dashboard-link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  color: #333;
  text-decoration: none;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.dashboard-link:hover {
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  color: white;
  transform: translateX(5px);
}

.dashboard-link-icon {
  font-size: 1.5rem;
}

/* Projects Styles */
.projects {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.projects-header {
  margin-bottom: 2rem;
}

.projects-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.project-card {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

/* Crypto Detection Styles */
.crypto-detection {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.crypto-header {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crypto-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.crypto-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.crypto-search {
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  width: 300px;
  font-size: 0.875rem;
}

.crypto-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #2193b0, #6dd5ed);
  color: white;
  border: none;
  cursor: pointer;
}

.crypto-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.crypto-grid {
  background: white;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard, .projects, .crypto-detection {
    padding: 1rem;
  }

  .dashboard-title, .projects-title, .crypto-title {
    font-size: 2rem;
  }

  .crypto-header {
    flex-direction: column;
    gap: 1rem;
  }

  .crypto-controls {
    width: 100%;
    flex-direction: column;
  }

  .crypto-search {
    width: 100%;
  }
}
